.image-browser-component .drop-area {

  width: auto;
  height: var(--size-11);

  border: var(--size-1) dashed #ddd;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: var(--size-4);
  margin-right: var(--size-4);
}

.image-browser-component .images-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--size-4);
  margin-bottom: var(--size-4);
  padding-inline: var(--size-4);
}

.image-browser-component .file-item {
  border: 5px solid #fff;
  padding: var(--size-4);
  width: var(--size-11);
  height: var(--size-11);
  overflow: hidden;
  position: relative;
}

.image-browser-component .file-item .preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.image-browser-component .file-item .preview-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-images-text-background);
  color: var(--color-images-text-foreground);
  padding: var(--size-2);
  font-size: var(--fsize-2);
  z-index: 20;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-browser-component .file-item .grayscale {
  filter: grayscale(100%);
}

.image-browser-component .uploading {
  border-color: var(--color-neutral-dark);
}

.image-browser-component .uploaded {
  border-color: transparent;
}

.image-browser-component .selected {
  border-color: var(--color-primary);
  box-shadow: 0 0 var(--size-2) 0 var(--color-neutral-light-alt2);
}

.image-browser-component .upload-error {
  border-color: red;
}

.image-browser-component .hourglass {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--fsize-6);
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  color: var(--color-neutral-dark);
  z-index: 30;
  cursor: wait;
}

@keyframes jiggle {

  0%,
  100% {
    transform: rotate(0deg)
  }

  5% {
    transform: rotate(-5deg)
  }

  10% {
    transform: rotate(5deg)
  }

  15% {
    transform: rotate(0deg)
  }
}

.image-browser-component .jiggle {
  animation: jiggle 2s infinite;
}