.product-item .gallery-and-description {
  display: flex;
  gap: var(--size-4);
  flex-direction: row;
}

.product-item .description-and-price {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  width: 100%;
}

.product-item .product-extra-info {
  margin-top: var(--size-9);
}

.product-item .product-gallery {
  flex-grow: 1;
  flex-basis: 1;
  max-width: calc(var(--size-12) * 1);
  min-width: 60%;
  width: 100%;
}

.product-item .product-description {
  white-space: pre-line;
  color: var(--color-text);
}

@media (max-width: 800px) {

  .product-item .product-name {
    font-size: var(--fsize-4);
  }

  .product-item .gallery-and-description {
    flex-direction: column;
    gap: var(--size-6);
  }

  .product-item .product-gallery {
    max-width: 100%;
    min-width: 100%;
  }
}