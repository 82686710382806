
.image-caption-component {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-images-text-background);
  color: var(--color-images-text-foreground);
  padding: var(--size-2);
  font-size: var(--fsize-3);
  z-index: 20;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
}
