.site-admin-control-button-component {
  position: absolute;

  top: var(--size-6);
  right: var(--size-6);
  width: var(--size-9);
  height: var(--size-9);
  z-index: 100;
}

.action-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 0 var(--color-neutral-dark);
}