.component-header {
  background-color: white;
  height: var(--size-10);

  align-items: center;
  padding: var(--size-4);
  gap: var(--size-7);

  border-bottom: 1px solid color-mix(in srgb, var(--color-neutral-light-alt2) 50%, transparent);
}

.component-header .logo-holder {
  width: calc(var(--size-10) * 0.80);
  padding: var(--size-2);
  cursor: pointer;
}

.component-header a {
  text-decoration: none;
}

.component-header .item {
  padding: var(--size-2) var(--size-4);
  border-radius: var(--size-2);

  font-size: var(--fsize-5);
  color: var(--color-neutral-dark);

  cursor: pointer;
}

.component-header .item:hover {
  background-color: var(--color-neutral-light-alt1);
}

.component-header .header-items {
  width: 100%;
  align-items: center;
}

.component-header .side-menu-toggle {
  display: none;
  flex-grow: 1;
  font-size: var(--fsize-6);
}

.side-panel-navigation {
  height: 50vh;
}

.side-panel-navigation .side-panel-item {
  font-size: var(--fsize-4);
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;
}

.side-panel-navigation a {
  text-decoration: none;
  color: var(--color-primary-dark);
}

@media (max-width: 1000px) {
  .component-header .item {
    font-size: var(--fsize-4);
  }
}

@media (max-width: 800px) {
  .component-header .header-items {
    display: none;
  }

  .component-header .side-menu-toggle {
    display: block;
  }
}