.list-and-item-component {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--size-6);
}

.list-and-item-component>div:first-child {
  flex-grow: 0;
}

.list-and-item-component>div:last-child {
  flex-grow: 1;
}