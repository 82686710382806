.order-list-item-component {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  gap: var(--size-4);
  align-items: center;
}

.order-list-item-component .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.order-list-item-component .indicator {
  width: var(--size-5);
  height: var(--size-5);
  border-radius: var(--size-1);
}