.jumbotron-component {

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  padding: var(--size-9) var(--size-4);

  border: var(--size-2) solid var(--color-primary);
  border-radius: var(--size-9);

  font-size: var(--fsize-9);
  font-weight: 800;
  letter-spacing: -0.1rem;
  line-height: 1.1;
  text-wrap: balance;
}

@media (max-width: 600px) {
  .jumbotron-component {
    font-size: var(--fsize-7);
    padding: var(--size-6) var(--size-3);
    border-radius: var(--size-7);
    letter-spacing: 0.0rem;
  }
}