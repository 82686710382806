.dynamic-site-footer-component {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  left: 0px;
  background-color: var(--color-background-alt2);
  font-size: var(--fsize-2);
  border-top: 1px solid color-mix(in srgb, var(--color-neutral-light-alt2) 50%, transparent);
  z-index: 100;
}

.dynamic-site-footer-component a {
  text-decoration: none;
}

.dynamic-site-footer-component .logo {
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
}

.dynamic-site-footer-component .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: var(--color-text);
}