.error-list-component {

  display: flex;
  flex-direction: column;

  margin-top: var(--size-4);

  border: 1px solid red;

  border-radius: var(--size-2);
  padding: var(--size-4);
}