.front-page-categories-component .categories-list {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: var(--size-4);
  container-type: inline-size;
}

@media (max-width: 480px) {
  .front-page-categories-component .categories-list {
    justify-items: stretch;
    flex-direction: column;
  }
}