.element-configuration-snippet-image-preview-component {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.element-configuration-snippet-image-preview-component .image-parent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.element-configuration-snippet-image-preview-component .image-parent img {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}