@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap');
@import url("../styles/output/theme-colors-internal.css");
@import url("../styles/output/spacing.css");
@import url("../styles/output/font-sizing.css");
@import url("../styles/output/sizings.css");

* {
  box-sizing: border-box;


  font-family: 'Twemoji Country Flags', "Rubik", sans-serif;
  font-optical-sizing: auto;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: var(--color-text) !important;
}