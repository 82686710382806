.image-gallery-component {
  width: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: var(--size-4);

  max-width: var(--dynamic-image-gallery-width-4);
}

.image-gallery-component .active-item {
  min-width: var(--dynamic-image-gallery-width-4);
  max-width: var(--dynamic-image-gallery-width-4);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  position: relative;
}

.image-gallery-component .gallery {
  max-height: var(--dynamic-image-gallery-heigth-4);
  height: var(--dynamic-image-gallery-heigth-4);
  aspect-ratio: 1;
}

.image-gallery-component .active-item img {
  max-height: var(--dynamic-image-gallery-heigth-4);
  max-width: var(--dynamic-image-gallery-width-4);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-gallery-component .thumbnails {
  display: flex;
  overflow-x: auto;
  gap: var(--size-4);
  max-width: 100%;
  white-space: nowrap;
}

.image-gallery-component .thumbnail-item {
  cursor: pointer;
  border: var(--size-1) solid #ccc;
  border-radius: var(--size-2);
  padding: 2px;
  height: 100px;
  width: 100px;
  min-width: 100px;
}

.image-gallery-component .thumbnail-item-selected {
  border-color: var(--color-primary);
}

.image-gallery-component .thumbnail-item img {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}