.site-editor-header-component {
  display: flex;
  justify-content: end;
  padding: var(--size-3);
  border-bottom: 1px solid var(--color-primary-light);
  min-height: var(--site-editor-header-heigth);
}

.site-editor-header-component .logo-holder {

  width: var(--size-9);
  padding: var(--size-2);
  cursor: pointer;
}

.site-editor-header-component a {
  text-decoration: none;
}

.site-editor-header-component .buttons-holder {
  display: flex;
  flex-direction: row;
  gap: var(--size-4);
  padding: var(--size-3);
}