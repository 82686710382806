.dynamic-page-blog-posts-list-component .blog-post {
  margin-bottom: var(--size-8);
  border-left: 4px solid var(--color-primary);
  padding: var(--size-5);
}

.dynamic-page-blog-posts-list-component .blog-post:hover {
  border-color: var(--color-primary-dark);
  background-color: var(--color-background-alt1);
}

.dynamic-page-blog-posts-list-component a {
  text-decoration: none;
}

.dynamic-page-blog-posts-list-component .blog-post .title {
  font-size: var(--fsize-5);
  font-weight: 500;
  color: var(--color-text);
}

.dynamic-page-blog-posts-list-component .blog-post .subtitle {
  font-size: var(--fsize-3);
  font-weight: 500;
  color: var(--color-text);
}

.dynamic-page-blog-posts-list-component .blog-post .date {
  color: var(--color-primary);
}