.container-labeled-component {
  width: 100%;
  height: 100%;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-color: var(--color-neutral-light-alt1);
}

.container-labeled-component .container-label {
  padding-inline: var(--size-4);
  padding-block: var(--size-2);
  font-size: var(--size-4);
  font-weight: 600;
  border-bottom: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
  user-select: none;
}

.container-labeled-component .container-content {
  padding: var(--size-4);
  border-radius: var(--bs-border-radius);
  background-color: var(--color-neutral-light);
}

.container-labeled-component .container-content.closed {
  display: none;
}

.container-labeled-component .container-content.opened {
  display: block;
}