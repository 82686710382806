.admin-user-queries-list-item-component {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--size-4);
}

.admin-user-queries-list-item-component .contact-and-message {
  flex-grow: 1;
  overflow: hidden;
}

.admin-user-queries-list-item-component .date-and-actions {
  flex-grow: 0;
  min-width: var(--size-10);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
}

.admin-user-queries-list-item-component .contact {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-user-queries-list-item-component .message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-user-queries-list-item-component .date {
  text-align: right;
}

.admin-user-queries-list-item-component .text-unread {
  font-weight: 600;
}

.admin-user-queries-list-item-component .text-unread {
  font-weight: 600;
}