.component-contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.component-contact .contact-data {
  flex-grow: 1;
}

.component-contact .contact-groups {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block: 2rem;
}

.component-contact .contact-group-name {
  font-weight: 500;
  color: var(--color-primary);
}

.component-contact .value {
  color: var(--color-text);
}

.component-contact .contact-map {
  max-width: calc(var(--size-12) * 3);
  border: 1px solid var(--color-neutral-light-alt1);
  width: 100%;
  height: calc(var(--size-12) * 2);
}