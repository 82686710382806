.tree-store-product-group-component .category-name {
  height: var(--size-8);
  background-color: var(--color-primary-light);
  color: var(--color-primary-light-opposite);
  display: flex;
  flex-direction: column;
  padding-inline: var(--size-4);
  justify-content: center;
  font-weight: 500;

  cursor: pointer;

  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree-store-product-group-component .products-list {
  padding-left: var(--size-2);
}

.tree-store-product-group-component .product-name {

  margin: var(--size-2);
  height: var(--size-8);
  display: flex;
  flex-direction: column;
  padding-inline: var(--size-2);
  justify-content: center;

  border: none;
  border-left: var(--size-1) solid transparent;

  cursor: pointer;

  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree-store-product-group-component .product-name-selected {
  border-color: var(--color-primary);
  font-weight: bold;
}