.tree-component {
  max-height: 70vh;
  overflow-y: scroll;
  cursor: pointer;
  height: 100%;
}

.tree-component .tree-node-childs {
  padding-left: var(--size-3);
  cursor: pointer;
}

.tree-component .tree-node {
  height: var(--size-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: var(--size-2);
  margin-right: var(--size-4);

  width: 100%;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.tree-component .tree-node-selected {
  background-color: var(--color-primary-light);
  color: var(--color-primary-light-opposite);
}

.tree-component .tree-node-unavailable {
  color: lightgray;
}


.tree-component .tree-node-initial-selected {
  font-weight: 500;
}