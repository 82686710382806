.why-us-component .title {
  text-align: center;
  margin-block: var(--size-7);
  font-size: var(--fsize-5);
  font-weight: 600;
  line-height: 1.1;
  color: var(--color-neutral-dark);
}

.why-us-component .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--size-6);
}

.why-us-component .content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: var(--size-6);
}

.why-us-component .content-item-title {
  font-size: var(--fsize-3);
  font-weight: 600;
}

.why-us-component .content-item-body {
  text-align: center;
}