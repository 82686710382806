.render-user-query-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: var(--size-6);
}

.render-user-query-component .user-query-parent {

  min-width: calc(var(--size-12));
  width: calc(var(--size-12) * 2);
  max-width: calc(var(--size-12) * 3);
}

.render-user-query-component .user-query-title {
  font-size: var(--fsize-5);
  font-weight: 500;
  margin-block: var(--size-4);
  color: var(--color-text);
}

.user-query-parent .label {
  color: var(--color-text);
}