.color-picker-component {
  --background-color: var(--color-primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.color-picker-component input {
  height: var(--size-7);
  width: var(--size-7);
  margin: var(--size-1);
  margin-right: var(--size-2);

  padding: 0;
  border-radius: var(--size-1);
  border-width: 1.5px;
  border-color: #2e2e2e;
}

.color-picker-component .color-picker-component-latest {
  display: flex;
  flex-direction: row;
  padding-left: var(--size-1);
  padding-right: var(--size-2);
}

.color-picker-component .color-picker-component-latest-color {
  height: var(--size-6);
  width: var(--size-6);
  margin: var(--size-1);
  border-radius: var(--size-5);
  border: 2px solid rgb(241, 241, 241);
  transition: transform 0.1s;
}

.color-picker-component .color-picker-component-latest-color:hover {
  transform: scale(1.1);
}