.order-details-component {
  min-width: var(--size-13);
}

.order-details-component .group-name {
  font-size: var(--fsize-4);
  font-weight: 500;
  margin-bottom: var(--size-2);
}

.order-details-component .order-state {
  display: flex;
  flex-direction: column;
  gap: var(--size-1);
}