.product-cart-item-component {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--size-4);

  align-items: center;
}

.product-cart-item-component .product-name {
  color: black;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--fsize-4);
}

.product-cart-item-component .product-quantity {
  flex-grow: 0;
  font-size: var(--fsize-4);
  max-width: var(--size-10);
}

.product-cart-item-component .product-price {
  font-size: var(--fsize-4);
  font-weight: 500;
  flex-grow: 0;
}

.product-cart-item-component .product-name-price {
  flex-grow: 1;
  align-items: center;
}

.product-cart-item-component .product-quantity-management {
  flex-grow: 0;
  justify-content: flex-end;
  width: auto;
}

@media (max-width: 800px) {

  .product-cart-item-component {
    flex-direction: column;
    gap: var(--size-1);
    margin-bottom: var(--size-7);
  }

  .product-cart-item-component .product-quantity-management {
    width: 100%;
  }
}