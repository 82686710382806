.element-configuration-snippet-text-input-component {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--size-4);
}

.element-configuration-snippet-text-input-component label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: var(--size-10);
}

.element-configuration-snippet-text-input-component input {
  flex-grow: 1;
}