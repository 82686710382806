.page-1-click-component .banner {
  border: var(--size-1) solid var(--color-primary);
  border-radius: var(--size-4);
  padding-block: var(--size-6);
  padding-inline: var(--size-8);

  font-size: var(--fsize-5);
  background-color: white;
  text-align: center;
  color: var(--color-primary);
  font-weight: 500;
}

.page-1-click-component .text-wall {
  margin-top: var(--size-5);
  font-size: var(--fsize-4);
}