.order-state-component {
  border: 1px solid var(--color-neutral-light-alt2);
  padding: var(--size-3);
  width: var(--size-10);
  font-size: var(--fsize-2);
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--size-2);
  transition: scale 0.1s;
}

.order-state-component:hover {
  scale: 1.05;
}