.page-pane-blog-entries-component .blog-posts-side-panel {
  min-width: var(--size-12);
  max-width: var(--size-12);
}

.page-pane-blog-entries-component .blog-posts-side-panel .list-holder {
  min-height: var(--size-12);
  display: flex;
  flex-direction: column;
}

.page-pane-blog-entries-component .blog-posts-side-panel .list-holder>*:first-child {
  flex-grow: 1;
}