:root {
  --site-editor-header-heigth: 87px;
}

.site-editor-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.site-editor-component .work-area {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: '100%';
  width: '100%';
}

.site-editor-component .main-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--site-editor-header-heigth));
  overflow-y: scroll;
}

.site-editor-component .content-holder {
  flex-grow: 1;
}