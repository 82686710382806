.editor-user-image-gallery-node-parent {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-bottom: var(--size-4);
}

.editor-user-image-gallery-node .gallery-holder {
  max-width: var(--dynamic-image-gallery-width-4);
}

.editor-user-image-gallery-node .gallery-caption {
  font-style: italic;
  color: var(--color-neutral-dark-alt1);
  font-size: var(--fsize-3);
  margin-top: var(--fsize-3);
  margin-bottom: 0;
  line-height: 1.8em;
}