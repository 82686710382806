.total-row {
  display: flex;
  flex-direction: row;
  margin-top: var(--size-8);
  align-items: center;

  border-top: 1px solid var(--color-neutral-light-alt1);
  padding-top: var(--size-4);
  
}

.total-label {
  font-size: var(--fsize-5);
}

.total-price {
  font-size: var(--fsize-5);
  font-weight: 500;
  margin-top: var(--size-4);
}