.pagination-component .pagination {
  margin-block: 0;
}

.pagination-component .pagination * {
  border-color: transparent;
}

.pagination-component .page-link {
  background-color: transparent;
}