.items-list-component {
  border: 1px solid var(--color-neutral-light-alt2);
  display: flex;
  flex-direction: column;
  padding: var(--size-2);
  gap: var(--size-1);
  height: 100%;
  overflow-y: auto;
}

.items-list-component .item {
  padding: var(--size-2);
  background-color: var(--color-neutral-light);
  border: none;
  border-left: var(--size-1) solid transparent;
  cursor: pointer;
  color: black;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 100%;
  min-height: var(--size-8);
}

.items-list-component .selected {
  border-color: var(--color-primary);
  font-weight: bold;
}