.site-editor-side-panel-component {
  min-width: calc(var(--size-12) * 1.3);
  max-width: calc(var(--size-12) * 1.3);

  background-color: var(--color-neutral-light-alt1);
  padding: var(--size-6);

  display: flex;
  flex-direction: column;
  gap: var(--size-1);
}

.site-editor-side-panel-component .item {
  padding-block: var(--size-2);
  padding-left: var(--size-2);
  border-left: var(--size-1) solid transparent;
  min-height: var(--size-8);
  color: var(--color-neutral-dark);
}

.site-editor-side-panel-component .item-selected {
  border-left: var(--size-1) solid var(--color-primary);
  color: black;
}

.site-editor-side-panel-component .item .page-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.site-editor-side-panel-component .item-site {
  margin-bottom: var(--size-5);
}

.site-editor-side-panel-component .add-page-holder {
  margin-top: var(--size-6);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.site-editor-side-panel-component .item-children {
  padding-left: var(--size-3);
}