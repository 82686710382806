.dynamic-page-blog-post-component .title-holder {
  margin-bottom: var(--size-8);
}

.dynamic-page-blog-post-component .content-holder {
  margin-bottom: var(--size-8);
}

.dynamic-page-blog-post-component .title {
  font-size: var(--fsize-7);
  font-weight: 500;
  color: var(--color-text)
}

.dynamic-page-blog-post-component .subtitle {
  margin-top: var(--size-2);
  font-size: var(--fsize-5);
  font-weight: 500;
  color: var(--color-text)
}

.dynamic-page-blog-post-component .date {
  color: var(--color-primary);
}