.order-line-details-component {
  border-bottom: 1px solid var(--color-neutral-light-alt1);
  padding-block: var(--size-1);
}

.order-line-details-component .order-line {
  display: flex;
  justify-content: space-between;
}

.order-line-details-component .order-line .product-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-line-details-component .order-line .product-price {
  flex-grow: 0;
  min-width: var(--size-10);
  text-align: right;
}

.order-line-details-component .order-line .quantity {
  flex-grow: 0;
  min-width: var(--size-9);
  text-align: right;
}