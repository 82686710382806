.image-sorter-component .list {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  height: calc(var(--size-9) + var(--size-2));
  padding-bottom: var(--size-4);
}

.image-sorter-component .image-sorter-image {
  width: var(--size-9);
  height: var(--size-9);
  border: 1px solid #ccc;
  margin-right: var(--size-2);
}

.image-sorter-component .image-not-ready {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.image-sorter-component .image-sorter-image img {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}