.rich-text-editor-componenent .editor {
  border-radius: var(--size-2);
  margin-bottom: var(--size-1);
}

.rich-text-editor-componenent .editable {
  padding-block: var(--size-4);
  padding-inline: var(--size-4);
  border: 1px solid var(--color-accent2);
  padding-right: var(--size-9);
}

.rich-text-editor-componenent .height-constrained {
  max-height: 800px;
  overflow-y: auto;
}

.rich-text-editor-componenent .editor:focus {
  outline: none;
}

.rich-text-editor-componenent .editor .editor-img {
  max-width: 300px;
}

.editor .editor-image-caption {
  font-style: italic;
  color: gray;
}