.admin-user-queries-list-component {
  max-width: calc(var(--size-12) * 2);
}

.admin-user-queries-list-component .item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.admin-user-queries-list-component .list-group-item {
  min-height: var(--size-10);
  border: 1px solid var(--color-neutral-light-alt2);
}

.admin-user-queries-list-component .extra-item {
  background-color: var(--color-neutral-light);
}