.home-page .main-container {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);
  margin-block: var(--size-10);
}

.home-page .message-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-block: var(--size-4);
  margin-inline: var(--size-9);

  text-align: center;
  font-size: var(--fsize-5);
  font-weight: 600;
  line-height: 1.1;
  color: var(--color-neutral-dark-alt2);
}

.action-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--size-7);
}

.complex-sites {
  border: var(--size-1) solid var(--color-primary);
  border-radius: var(--size-4);
  padding-block: var(--size-6);
  padding-inline: var(--size-8);
}

@media (max-width: 600px) {

  .home-page .main-container {
    gap: var(--size-9);
  }

  .home-page .message-1 {
    margin-inline: var(--size-7);
  }

  .complex-sites {
    padding-block: var(--size-6);
    padding-inline: var(--size-6);
  }
}