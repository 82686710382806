.product-list-item-component {
  width: calc(var(--size-11) * 1.4);
  max-width: calc(var(--size-11) * 1.4);

  transition: scale 0.3s;
}

.product-list-item-component:hover {
  scale: 1.05;
}

.product-list-item-component .image-parent {
  background-color: var(--color-neutral-light-alt1);

  height: calc(var(--size-11) * 1.4);
  width: calc(var(--size-11) * 1.4);
  max-height: calc(var(--size-11) * 1.4);
  max-width: calc(var(--size-11) * 1.4);

  border-radius: var(--size-6);
  overflow: hidden;
}

.product-list-item-component .image {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-list-item-component .product-name {
  color: var(--color-neutral-dark);
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-size: var(--fsize-4);
  font-weight: 500;
  margin-top: var(--size-2);
}

.product-list-item-component .product-price {
  font-size: var(--fsize-5);
  color: var(--color-text-alt2);
  margin-top: calc(var(--size-1) * -1);
}