.checkout-cart-summary-component .summary-label {
  font-size: var(--fsize-4);
  font-weight: 500;
}

.checkout-cart-summary-component .cart-item-quantity {
  display: inline-block;
  width: 4rem;
  text-align: right;
}

.checkout-cart-summary-component .total {
  font-weight: 500;
  font-size: var(--fsize-4);
}