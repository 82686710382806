.element-configuration-snippet-gallery-config-component .images-list {
  display: flex;
  flex-direction: row;
  gap: var(--size-4);
  padding-right: var(--size-4);
}

.element-configuration-snippet-gallery-config-component .image-item {
  width: var(--size-9);
  height: var(--size-9);
  border: var(--size-1) solid transparent;
}

.element-configuration-snippet-gallery-config-component .image-item-selected {
  border-color: var(--color-primary);
}

.element-configuration-snippet-gallery-config-component .image-item-not-found {
  border: var(--size-1) solid var(--color-neutral-dark-alt2);
  display: flex;
  align-items: center;
  text-align: center;
}

.element-configuration-snippet-gallery-config-component .image-item img {
  max-height: 100px;
  max-width: 100px;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}