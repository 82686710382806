.dynamic-site-header-component {

  background-color: var(--color-background-alt2);
  overflow: hidden;

  padding: var(--size-4);
  gap: var(--size-7);

  border-bottom: 1px solid color-mix(in srgb, var(--color-neutral-light-alt2) 50%, transparent);

  font-size: var(--fsize-7);
}

.dynamic-site-header-component .header {
  align-items: center;
}

.dynamic-site-header-component .side-menu-toogle {
  font-size: clamp(var(--fsize-6), 5vw, var(--fsize-8));
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
}

@media (width >=992px) {
  .dynamic-site-header-component .side-menu-toogle {
    display: none;
  }
}

.dynamic-site-header-component .site-name {
  color: var(--color-text);

  font-size: clamp(var(--fsize-6), 5vw, var(--fsize-8));
}