.vertical-line-component {
  height: 100%;
  padding-block: var(--size-2);
  width: 1px;
}

.vertical-line-component .line {
  height: 100%;
  width: 1px;
  border-left: 1px solid white;
}