.element-settings-floating-toolbar {

  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 90;
  background-color: var(--color-neutral-light);
  display: flex;
  flex-direction: row;
  padding: var(--size-2);
  border: 1px solid var(--color-neutral-light-alt1);
  cursor: default;
}

.element-settings-floating-toolbar button {
  border: none;
}

.element-settings-floating-toolbar .item {
  font-size: var(--fsize-4);
}

.element-settings-floating-toolbar .label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-12);
  text-align: center;
  border-right: 2px solid var(--color-neutral-light-alt1);
  margin-right: var(--size-4);
  overflow: hidden;
  white-space: nowrap;
}

.element-settings-floating-toolbar .sep {
  min-width: var(--size-4);
}