:root {
  --threshold-width-compact-layout: 800px;
}

.dynamic-page-renderer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dynamic-page-renderer .page-content-home {
  margin-top: var(--size-9);
}

.dynamic-page-renderer .page-content {
  margin-bottom: var(--size-10);
}

.dynamic-page-renderer .side-navigation-container {
  margin-top: var(--size-9);
}

.dynamic-page-renderer .side-navigation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: var(--size-12);
  overflow-y: auto;
}

.off-canvas-panel {
  background-color: var(--color-background-alt2) !important;
}

.off-canvas-panel .off-canvas-panel-title {
  color: var(--color-text);
}

.off-canvas-panel button {
  color: aquamarine;
}

@media (width < 992px) {
  .dynamic-page-renderer .side-navigation-container {
    display: none;
  }
}