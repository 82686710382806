#alert-inline-component {
  margin-bottom: 0;
  padding-block: var(--size-2);
  padding-inline: var(--size-4);
}

.alert-inline-component {
  margin-bottom: 0;
  padding-block: var(--size-2);
  padding-inline: var(--size-4);
  border: 1px solid transparent;
}

.alert-inline-component-danger {
  background-color: var(--bs-danger-bg-subtle);
  border-color: var(--bs-danger-border-subtle);
  color: var(--bs-danger-text-emphasis);
}

.alert-inline-component-warning {
  background-color: var(--bs-warning-bg-subtle);
  border-color: var(--bs-warning-border-subtle);
  color: var(--bs-warning-text-emphasis);
}

.alert-inline-component-info {
  background-color: var(--color-neutral-light);
  border-color: var(--color-neutral-light-alt2);
  color: var(--color-neutral-dark);
}

.alert-inline-component-success {
  background-color: var(--bs-success-bg-subtle);
  border-color: var(--bs-success-border-subtle);
  color: var(--bs-success-text-emphasis);
}