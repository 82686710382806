.component-logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    container-type: inline-size;
}

.component-logo-square {
    aspect-ratio: 1;
    background: linear-gradient(to bottom left,
            #FFE209 0%,
            #FFE83F 47%,
            #F2B212 100%);
    box-shadow: rgba(0, 0, 0, 0.40) -2cqw -2cqw 2cqw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.component-logo-text-pixel {
    color: black;
    line-height: 32cqw;
    font-size: 26cqw;
    position: relative;
    top: -0cqw;
    text-transform: uppercase;
    text-decoration: none;

    font-weight: 700;
    letter-spacing: 0.06em;
}

.component-logo-text-vaidoso {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18cqw;
    line-height: 26cqw;
    font-weight: 450;
    letter-spacing: 0.2em;

    color: black;
    text-transform: uppercase;

    color: var(--color-text);
}