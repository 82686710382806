.front-page-category {
  border: 1px solid #e0e0e0;
  height: var(--size-11);
  width: var(--size-12);

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-primary);

  border-radius: var(--size-6);
  overflow: hidden;

  transition: scale 0.3s;
}

.front-page-category:hover {
  scale: 1.05;
}

.front-page-category .name {
  font-weight: 500;
  font-size: var(--fsize-4);
  text-align: center;

  color: var(--color-primary-opposite);
}

@container (width < 650px) {
  .front-page-category {
    height: var(--size-10);
    width: var(--size-11);
  }

  .front-page-category .name {
    font-size: var(--fsize-3);
  }
}

@container (width <=400px) {
  .front-page-category {
    max-width: none;
    width: 100%;
  }
}