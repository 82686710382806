$color-primary-dark: #4349AB;
$color-primary: #5962E4;
$color-primary-light: #8E91F7;

$color-primary-opposite: #000000;
$color-primary-light-opposite: #000000;

$color-secondary-dark: #C7BD49;
$color-secondary: #F0E458;
$color-secondary-light: #F0EF93;

$color-neutral-light: #fcfcfc;
$color-neutral-light-alt1: #dbdbdb;
$color-neutral-light-alt2: #95a5a6;

$color-neutral-dark: #2c3e50;
$color-neutral-dark-alt1: #34495e;
$color-neutral-dark-alt2: #7f8c8d;

$color-text: "#2c3e50";
$color-text-alt1: "#34495e";
$color-text-alt2: "#7f8c8d";

$color-background: "#fcfcfc";
$color-background-alt1: "#dbdbdb";
$color-background-alt2: "#95a5a6";


$color-accent1: #f1c40f;
$color-accent2: #1abc9c;
$color-accent3: #9b59b6;

$color-images-text-background: rgba(255, 255, 255, 0.8);
$color-images-text-foreground: black;