
.editor-user-image-node-parent {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-bottom: var(--size-4);
}

.editor-user-image-node {
  max-width: 800px;
}