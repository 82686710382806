.dynamic-site-tree-navigation-component {
  min-width: var(--size-12);
}

.dynamic-site-tree-navigation-component .item {
  padding-block: var(--size-2);
  padding-left: var(--size-2);
  border-left: var(--size-1) solid transparent;
}

.dynamic-site-tree-navigation-component .item-children {
  padding-left: var(--size-3);
}

.dynamic-site-tree-navigation-component a {
  text-decoration: none;
}

.dynamic-site-tree-navigation-component .item {
  padding: var(--size-2) var(--size-4);
  border-radius: var(--size-2);

  font-size: var(--fsize-3);
  color: var(--color-text);
  cursor: pointer;
}

.dynamic-site-tree-navigation-component .item:hover {
  background-color: var(--color-primary-light);
  color: var(--color-primary-light-opposite);
}