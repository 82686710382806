.element-configuration-snippet-youtube-video-preview-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.element-configuration-snippet-youtube-video-preview-component .no-video-id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--fsize-9);
  height: var(--size-12);
}