:root {
  /*
  These colors don't matter much. 
  But this is important because we are going to override 
  these colors, by changing them later.
  */

  /* Button */

  --style-var-btn-shadow-focus: 0 0 0 0.25rem #ffea00;

  /* Button Primary*/
  --style-var-btn-primary-color-text: #ff00ea;
  --style-var-btn-primary-color-back: #ffea00;
  --style-var-btn-primary-color-border: #ffea00;

  --style-var-btn-primary-color-text-hover: #ff00ea;
  --style-var-btn-primary-color-back-hover: #ffea00;
  --style-var-btn-primary-color-border-hover: #ffea00;

  --style-var-btn-primary-color-text-active: #ff00ea;
  --style-var-btn-primary-color-back-active: #ffea00;
  --style-var-btn-primary-color-border-active: #ffea00;

  --style-var-btn-primary-color-text-disabled: #ff00ea;
  --style-var-btn-primary-color-back-disabled: #ffea00;
  --style-var-btn-primary-color-border-disabled: #ffea00;

  /* Button Outline */
  --style-var-btn-outline-color-text: #ff00ea;
  --style-var-btn-outline-color-back: #ffea00;
  --style-var-btn-outline-color-border: #ffea00;

  --style-var-btn-outline-color-text-hover: #ff00ea;
  --style-var-btn-outline-color-back-hover: #ffea00;
  --style-var-btn-outline-color-border-hover: #ffea00;

  --style-var-btn-outline-color-text-active: #ff00ea;
  --style-var-btn-outline-color-back-active: #ffea00;
  --style-var-btn-outline-color-border-active: #ffea00;

  --style-var-btn-outline-color-text-disabled: #ff00ea;
  --style-var-btn-outline-color-back-disabled: #ffea00;
  --style-var-btn-outline-color-border-disabled: #ffea00;

  /* Button Danger */
  --style-var-btn-danger-color-back-hover: #f55959;

  /* Toggle */

  --style-var-toggle-color-back: #ffea00;
  --style-var-toggle-color-border: #ffea00;

  --style-var-toggle-box-shadow-focus: 0 0 0 0.25rem #ffea00;

  --style-var-toggle-circle: #ffea00;
  --style-var-toggle-circle-checked: #ffea00;

  /* Form Control */

  --style-var-form-control-color-border-focus: #ffea00;
  --style-var-form-control-shadow-focus: 0 0 0 0.25rem #ffea00;

  /* Dropdown Item active */
  --style-var-dropdown-item-active: #ffea00;

  --color-neutral-light: #ffea00;
  --color-text: #ffea00;

  /* Close button*/
  --style-var-btn-close-image: #ffea00;

  /* Debug */
  --test-debug: #ff0000;
}


.btn {
  --bs-btn-focus-box-shadow: var(--style-var-btn-shadow-focus);
}

.btn:hover {
  --bs-btn-hover-bg: var(--style-var-btn-color-back-hover);
}

.btn:focus-visible {
  --bs-btn-hover-bg: var(--style-var-btn-color-back-hover);
}

.btn-primary {

  /*
  This is where we are going to indicate to Bootstrap that it should be 
  using our custom colors.
  */

  --bs-btn-color: var(--style-var-btn-primary-color-text);
  --bs-btn-bg: var(--style-var-btn-primary-color-back);
  --bs-btn-border-color: var(--style-var-btn-primary-color-border);

  --bs-btn-hover-color: var(--style-var-btn-primary-color-text-hover);
  --bs-btn-hover-bg: var(--style-var-btn-color-primary-back-hover);
  --bs-btn-hover-border-color: var(--style-var-btn-primary-color-border-hover);

  --bs-btn-active-color: var(--style-var-btn-primary-color-text-active);
  --bs-btn-active-bg: var(--style-var-btn-primary-color-back-active);
  --bs-btn-active-border-color: var(--style-var-btn-primary-color-border-active);

  --bs-btn-disabled-color: var(--style-var-btn-primary-color-text-disabled);
  --bs-btn-disabled-bg: var(--style-var-btn-primary-color-back-disabled);
  --bs-btn-disabled-border-color: var(--style-var-btn-primary-color-border-disabled);
}

.btn-outline-primary {

  --bs-btn-color: var(--style-var-btn-outline-color-text);
  --bs-btn-bg: var(--style-var-btn-outline-color-back);
  --bs-btn-border-color: var(--style-var-btn-outline-color-border);

  --bs-btn-hover-color: var(--style-var-btn-outline-color-text-hover);
  --bs-btn-hover-bg: var(--style-var-btn-outline-color-back-hover);
  --bs-btn-hover-border-color: var(--style-var-btn-outline-color-border-hover);

  --bs-btn-active-color: var(--style-var-btn-outline-color-text-active);
  --bs-btn-active-bg: var(--style-var-btn-outline-color-back-active);
  --bs-btn-active-border-color: var(--style-var-btn-outline-color-border-active);

  --bs-btn-disabled-color: var(--style-var-btn-outline-color-text-disabled);
  --bs-btn-disabled-bg: var(--style-var-btn-outline-color-back-disabled);
  --bs-btn-disabled-border-color: var(--style-var-btn-outline-color-border-disabled);
}

.btn-danger {
  --bs-btn-hover-bg: var(--style-var-btn-danger-color-back-hover) !important;
}

.form-check-input:checked {
  background-color: var(--style-var-toggle-color-back);
  border-color: var(--style-var-toggle-color-border);
}

.form-check-input:focus {
  border-color: var(--style-var-toggle-color-border);
  box-shadow: var(--style-var-toggle-box-shadow-focus);
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: var(--style-var-toggle-circle);
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: var(--style-var-toggle-circle-checked);
}

.form-control:focus {
  border-color: var(--style-var-form-control-color-border-focus);
  box-shadow: var(--style-var-form-control-shadow-focus);
}

.btn-close:focus {
  box-shadow: var(--style-var-btn-shadow-focus);
}

.dropdown-item:active {
  background-color: var(--style-var-dropdown-item-active);
}

.list-group-item.active {
  border-color: var(--style-var-list-group-item-selected-color-border);
}

.btn-close {
  --bs-btn-close-bg: var(--style-var-btn-close-image);
}