.page-common-settings-component {
  width: 100%;
}

.page-common-settings-component .label {
  width: var(--size-11);
}

.page-common-settings-component .input {
  width: var(--size-12);
}