.page-sign-up .content-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-sign-up .content {
  max-width: var(--size-13);
  width: var(--size-13);
}

.page-sign-up .buttons-holder {
  display: flex;
  flex-direction: row;
  gap: var(--size-4);
}

.page-sign-up .buttons-holder *:first-child {
  flex-grow: 1;
}