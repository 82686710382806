.create-site-action-component {
  border-radius: var(--size-3);
  background-color: var(--color-primary);
  padding: var(--size-8);
}

.create-site-action-component .message {
  text-align: center;
  font-size: var(--fsize-5);
  font-weight: 600;
  line-height: 1.1;
  color: var(--color-neutral-light);
}