.code-gen-actions
{
}

.code-gen-actions label
{
  min-width: 200px;
}

.code-gen-actions .edit-value
{
  width: 400px;
}

.code-gen-actions #module
{
  width: 400px;
}

.code-gen-actions .group
{
  margin-block: 4rem;
}

.code-gen-actions .question
{
  margin-bottom: 1rem;
}

.code-gen-actions .generated-code
{
  font-family: monospace;
}