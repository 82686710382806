.page-pane-store-products-component .products-side-panel {
  width: var(--size-12);
  min-width: var(--size-12);
}

.page-pane-store-products-component .items-list-component-parent {
  min-height: var(--size-12);
  /*Based on the product form height */
  max-height: 700px;
  overflow-y: auto;
  border: 1px solid var(--color-neutral-dark-alt2);
  border-radius: var(--size-2);
}