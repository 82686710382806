.flex-h {
  display: flex;
  flex-direction: row;
}

.flex-v {
  display: flex;
  flex-direction: column;
}

.back-red {
  background-color: #FF000040;
}

.flex-1 {
  flex: 1;
}

.no-decor {
  text-decoration: none;
}

.align-center {
  align-items: center;
}