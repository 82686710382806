.site-information-component {
  margin-bottom: var(--size-8);
}

.site-information-component .header {
  font-size: var(--fsize-4);
  font-weight: 500;
}

.site-information-component .label {
  width: var(--size-11);
  min-width: var(--size-11);
}

.site-information-component .value {
  word-break: break-word;
  overflow-x: hidden;
}