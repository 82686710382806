.contact-us-component {
  width: 100%;
  margin: 0;
  padding: 0;
}

.contact-us-component .parent {
  max-width: calc(var(--size-12) * 4);
  margin: auto;
}


.contact-us-component .option {

  padding: var(--size-6);
  background-color: var(--color-neutral-light);

  border: var(--size-1) solid var(--color-primary);
  border-radius: var(--size-4);

}

.contact-us-component .option .title {
  font-size: var(--fsize-3);
  font-weight: 600;
}