.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  transition: opacity 1.5s ease-in-out;
  /* Smooth fade effect */
  opacity: 0;
}

.image.fade-in {
  opacity: 1;
}

.image.fade-out {
  opacity: 0;
}