.render-youtube-video-component {
  align-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--size-4);
}

.render-youtube-video-component .render-youtube-video-component-caption {
  margin-top: var(--size-2);
  font-size: var(--fsize-3);
}