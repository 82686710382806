.add-site-page-dialog-component .page-type-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-2);
}

.add-site-page-dialog-component .page-type-item {
  width: var(--size-11);
  height: var(--size-11);
  margin: 5px;
  border: 1px solid black;
  border-radius: var(--size-2);
  padding: var(--size-4);
  font-size: var(--fsize-3);
  font-weight: 500;
}

.add-site-page-dialog-component .page-type-item-selected {
  background-color: var(--color-primary-light);
}

.add-site-page-dialog-component .page-type-items::after {
  content: "";
  flex: auto;
}